import React, { useState, useEffect, useMemo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import './licenseFaq.scss';

const LicenseFaq = ({ faqApiUrl }) => { 
  const [faqData, setFaqData] = useState([]);
  const [expanded, setExpanded] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  const selectedLanguage = useMemo(
    () => localStorage.getItem("selectedLanguage") || "kg",
    []
  );

  useEffect(() => {
    const fetchFaqData = async () => {
      try {
        const response = await axios.get(faqApiUrl); 
        setFaqData(response.data);
      } catch (err) {
        setError("Error fetching FAQ data");
      } finally {
        setLoading(false);
      }
    };
    fetchFaqData();
  }, [faqApiUrl]);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!faqData.length) return null;

  return (
    <div className="license__faq__container">
      <h2 className="license__faq__title">{t("faq.title")}</h2>
      {faqData.map((item) => (
        <Accordion 
          key={item.id}
          expanded={expanded === item.id}
          onChange={handleChange(item.id)}
        >
          <AccordionSummary expandIcon={<AddIcon />}>
            <Typography variant="body1" className="accordion__question">
              {selectedLanguage === "kg" ? item.question_kg : item.question_ru}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="body2" className="accordion__answer">
              {selectedLanguage === "kg" ? item.answer_kg : item.answer_ru}
            </Typography>
          </AccordionDetails>
        </Accordion>
      ))}   
    </div>
  );
};

export default LicenseFaq;
