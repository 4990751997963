import React, { useState, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import Layout from "./layout/Layout";
import AboutUs from "./pages/AboutUs/AboutUs";
import Camera from "./pages/Camera/Camera";
import Home from "./pages/Home/Home";
import Jobs from "./pages/Jobs/Jobs";
import News from "./pages/News/News";
import NewsDetails from "./pages/News/NewsDetails/NewsDetails";
import NotFound from "./pages/NotFound/NotFound";
import CalculatorServices from "./pages/Services/Calculator/CalculatorServices";
import RequestInspection from "./pages/Services/RequestForInspection/RequestInspection.jsx";
import Search from "./pages/Search/Search.jsx";
import PricingTable from "./pages/PricingTable/PricingTable.jsx";
import License from "./pages/License/License.jsx";
import { css } from "@emotion/react";
import { BeatLoader } from "react-spinners";
import Appeal from "./pages/Appeal/Appeal.jsx";
import Faq from "./pages/Faq/Faq.jsx";
import VsPage from "./pages/VsPage/VsPage.jsx";
import TsPage from "./pages/TsPage/TsPage.jsx";
import Npa from "./pages/Npa/Npa.jsx";

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false);
    }, 1500);

    return () => clearTimeout(timeout);
  }, []);

  const override = css`
    display: block;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;

  return (
    <div className="App">
      {loading ? (
        <div className="loading-overlay" css={override}>
          {loading ? (
            <BeatLoader color={"#18569e"} loading={loading} size={14} />
          ) : null}
        </div>
      ) : (
        <Routes>
          <Route path={"/"} element={<Layout />}>
            <Route index element={<Home />} />
            <Route path={"/about"} element={<AboutUs />} />
            <Route path={"/news"} element={<News />} />
            <Route path={"/news/:id"} element={<NewsDetails />} />
            <Route path={"/calculator"} element={<CalculatorServices />} />
            <Route path={"/pricing"} element={<PricingTable />} />
            <Route path={"/license"} element={<License />} />
            <Route path={"/search"} element={<Search />} />
            <Route path={"/appeal"} element={<Appeal />} />
            <Route path={"/faq"} element={<Faq />} />
            <Route path={"/vs"} element={<VsPage />} />
            <Route path={"/ts"} element={<TsPage />} />
            <Route path={"/npa"} element={<Npa />} />
            <Route
              path={"/request-for-inspection"}
              element={<RequestInspection />}
            />
            <Route path={"/camera"} element={<Camera />} />
            <Route path={"/jobs"} element={<Jobs />} />
            <Route path={"*"} element={<NotFound />} />
          </Route>
        </Routes>
      )}
    </div>
  );
}

export default App;
